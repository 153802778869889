import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './Team.css'
import axios from 'axios'

export default function Team() {
    const [team, setTeam] = useState([])

    useEffect(()=>{
        let url =(`${process.env.REACT_APP_TEAM_API}`)
        axios.get(url).then((res)=>{
            setTeam(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])


  return (
    <>
    <Header/>
    <div className="team-banner">
        <div className="team-banner-1">
            <h2> Our Team</h2>
        </div>
    </div>
    <div className="team">
        <div className="container">
            <div className="row">
                {
                    team?.map((team)=>{
                        return(
                            <>
                               <div className="col-md-3">
                                    <div key={team.id} className="teamcard">
                                        <div className="team-image">
                                            <img src={team.feature_image} alt={team.title.rendered} className='img-fluid' />
                                        </div>
                                        <h4> {team.title.rendered}</h4>
                                        <h6> {team.acf.team_position}</h6>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
        
            </div>
        </div>
    </div>
    <Footer/>
    </>
  )
}
