import React from 'react'
import { Whatsapp } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
export default function WhatsApp() {
  return (
    <>
        <div className="whatsapp">
            <Link to="https://wa.me/977981137667"> <Whatsapp/> Chat With Us </Link>
                

        </div>

    </>
  )
}
