import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {useState} from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import './About.css'

export default function About() {

  const [about, setAbout] = useState([])
  
  useEffect(()=>{
    let url =(`${process.env.REACT_APP_ABOUT_US_API}`)
    axios.get(url).then((res)=>{
      setAbout(res.data)
    }).catch(err=>{
      console.log(err)
    })
  },[])
  return (
    <>
    <Header/>
    <div className="about-banner">
      {
        about?.map((about)=>{
          return(
            <>
               <div key={about.id} className="card about-b">
                  <img src={about.feature_image} alt={about.title.rendered} className='img-fluid' />
                <div className="card-img-overlay about-title">
                  <h2> {about.title.rendered}</h2>
                </div>

              </div>
            </>
          )
        })
      }
   
    </div>
    <div className="aboutus">
      <div className="container">
        <div className="row">
          {
            about?.map((about)=>{
              return(
                <>
                  <div className="about-main-content">
                      <div dangerouslySetInnerHTML={{ __html: about.content.rendered }} />
                  </div>
                </>
              )
            })
          }
        
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}
