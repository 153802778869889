import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './Contact.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import WhatsApp from '../../components/WhatsApp'
import {GeoFill, TelephoneFill, Envelope} from 'react-bootstrap-icons'
import {Link} from 'react-router-dom'

export default function Contact() {

  const [contact, setContact] = useState([])

  useEffect(()=>{
    let url =(`${process.env.REACT_APP_CONTACT_PAGES_API_ROOT}`)
    axios.get(url).then((res)=>{
      setContact(res.data)
    }).catch(err=>{
      console.log(err)
    })
  },[])
  return (
    <>
    <Header/>
    {
      contact?.map((contact)=>{
        return(
          <>
            <div key={contact.id} className="contact-banner">
              <div className="contact-banenr-title">
                <h4>{contact.title.rendered} </h4>
              </div>
            </div>
              <div className="contact">
                <div className="container">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="contact-details">
                        <ul>
                          <li> <span className='contact-icon'> <GeoFill/> </span>
                              <span className='contact-text'> {contact.acf.address} </span> 
                           </li>
                          <li> <span className='contact-icon'> <TelephoneFill/> </span> 
                              <Link to={`tel: ${contact.acf.contact_number}`}>  <span className='contact-text'> {contact.acf.contact_number}  </span>  </Link>
                              <Link to={`tel: ${contact.acf.contact_number_2}`}> 
                            <span className='contact-text'> {contact.acf.contact_number_2}</span> 
                          </Link>
                          </li>
                   
                          <li> <span className='contact-icon'> <Envelope/> </span>  
                          <Link to={`mailto:${contact.acf.enail}`}> 
                              <span className='contact-text'> {contact.acf.enail} </span> 
                            <Link to={`mailto:${contact.acf.email_2}`}> 
                            <span className='contact-text'> {contact.acf.email_2} </span>  
                            </Link>
                          </Link>
                             
                           </li>
                     
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3">
                        <div className="whatsappmessage">
                          <WhatsApp/>
                        </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14133.534285320122!2d85.2772176!3d27.6745374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb193023d673c7%3A0xed539de887811e19!2sKmn%20Services!5e0!3m2!1sen!2snp!4v1702523492124!5m2!1sen!2snp" width="2000" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
              </div>
          </>
        )
      })
    }
        
    <Footer/>
    </>
  )
}
